/* tslint:disable */
/* eslint-disable */
/**
 * Nestjs Auth0 Template API
 * Nestjs Auth0 Template API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
/**
 * V1AdminApi - axios parameter creator
 * @export
 */
export const V1AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/download-template-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/download-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserTickets: async (ticketUniqueKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUniqueKey' is not null or undefined
            assertParamExists('downloadUserTickets', 'ticketUniqueKey', ticketUniqueKey)
            const localVarPath = `/v1/admin/download-user-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ticketUniqueKey !== undefined) {
                localVarQueryParameter['ticketUniqueKey'] = ticketUniqueKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importTickets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/import-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminApi - functional programming interface
 * @export
 */
export const V1AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemplateTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemplateTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadTemplateTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUserTickets(ticketUniqueKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUserTickets(ticketUniqueKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.downloadUserTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importTickets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importTickets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1AdminApi.importTickets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1AdminApi - factory interface
 * @export
 */
export const V1AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateTickets(options?: any): AxiosPromise<string> {
            return localVarFp.downloadTemplateTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTickets(options?: any): AxiosPromise<string> {
            return localVarFp.downloadTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketUniqueKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserTickets(ticketUniqueKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadUserTickets(ticketUniqueKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importTickets(options?: any): AxiosPromise<string> {
            return localVarFp.importTickets(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminApi - interface
 * @export
 * @interface V1AdminApi
 */
export interface V1AdminApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadTemplateTickets(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadTickets(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} ticketUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    downloadUserTickets(ticketUniqueKey: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApiInterface
     */
    importTickets(options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * V1AdminApi - object-oriented interface
 * @export
 * @class V1AdminApi
 * @extends {BaseAPI}
 */
export class V1AdminApi extends BaseAPI implements V1AdminApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadTemplateTickets(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadTemplateTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadTickets(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketUniqueKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public downloadUserTickets(ticketUniqueKey: string, options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).downloadUserTickets(ticketUniqueKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminApi
     */
    public importTickets(options?: RawAxiosRequestConfig) {
        return V1AdminApiFp(this.configuration).importTickets(options).then((request) => request(this.axios, this.basePath));
    }
}

